import React, { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useUser } from "hooks";

function FeatureFlags(): React.ReactElement
{
  const ldClient = useLDClient();
  const profile = useUser();

  useEffect(() =>
  {
    if (profile)
    {
      void ldClient?.identify({
        key: profile?.userid,
        anonymous: !profile,
      }).catch(err => console.error(err));
    }
  }, [profile]);

  return <></>;
}

export default FeatureFlags;