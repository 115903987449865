import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { Auth } from 'Authentication/Auth';
import { Main } from 'Page/';
import { AuthUserManager } from 'Authentication/AuthUserManager';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { IAppConfig } from 'IAppConfig';
import AppConfigContext from 'Context/AppConfigContext';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { NotificationModal } from 'Component/Notifications';
import TagManager from 'react-gtm-module';
import { useNotificationStore } from 'store';
import { Notification } from 'Component/Notifications';

let didInit = false;

function App(): React.ReactElement {
  const [config, setConfig] = useState<IAppConfig>();
  const { setNotification } = useNotificationStore();
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // Can be overriden for individual queries in useQuery
        retry: 2 // if a query fails it will be retried twice using the default retry delay
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        const e = error as Response;
        setNotification(e.status as Notification);
      }
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        const e = error as Response;
        setNotification(e.status as Notification);
      }
    })
  }))


  useEffect(() => {

    if (didInit)
      return;

    didInit = true;

    const connectToGTM = (data: IAppConfig) => {
      const tagManagerArgs = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        gtmId: data.MEASUREMENT_ID,
        dataLayer: {
          page: 'home'
        },
        dataLayerName: 'dataLayer'
      }
      TagManager.initialize(tagManagerArgs);
    }

    const fetchConfig = async () => {
      try {
        const res = await fetch('/app.config.json');
        const data = await res.json() as IAppConfig;
        window.appConfig = data;
        AuthUserManager.init({
          baseUrl: window.location.origin,
          authority: data.AUTHORITY,
          audience: data.AUDIENCE,
          clientId: data.CLIENT_ID,
        });
        setConfig(() => data);
        connectToGTM(data);
      } catch (err) {
        console.error(err);
      }
    }
    void fetchConfig();
  }, []);

  return config !== undefined ? (
    <AppConfigContext.Provider
      value={{
        config: config
      }}>
      <LDProvider clientSideID={config.LD_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Auth>
            <NotificationModal />
            <BrowserRouter>
              <div className="studio d-flex flex-column">
                <Main />
              </div>
            </BrowserRouter>
          </Auth>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LDProvider>
    </AppConfigContext.Provider>
  ) : (
    <></>
  );
}

export default App;